.FlexTable {
    width: 100%;
    border-collapse: collapse;
    border: 0px solid transparent;
    border-spacing: 0px;
}

.FlexLeft {
    width: 30%;
    border: 0px solid transparent;
}

.FlexMid {
    border: 0px solid transparent;

}
.FlexDiv {
    width: 640px;
    padding-left: 10px;

}

.FlexRight {
    width: 70%;
    border: 0px solid transparent;
}

@media only screen and (max-width: 650px) {
    .FlexLeft {
        width: 0px;
    }
    
    .FlexMid {
        width: 100%;
    
    }
    .FlexDiv {
        width: 100%;
    
    }
    
    .FlexRight {
        width: 0px;
    }
}