.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Text {
  padding-left: 15px;
  padding-right: 15px;
}


.Text h3 {
  font-family:  Lato,'Open Sans',sans-serif; 
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4em;

}
.Text p {
  font-family:  Lato,'Open Sans',sans-serif; 
  font-weight: 300;
  font-size: 24px;
  line-height: 1.4em;

  
}
.Text li {
  font-family:  Lato,'Open Sans',sans-serif; 
  font-weight: 300;
  font-size: 21px;
  line-height: 31.5px;
  
}

.Text .MyIcons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.Text .Contacts .MyIcons {
  font-size: 36px;
  color: #555;
}



.Text table {
  border-collapse: collapse;
}

.Text .Contacts {
 margin-right: 60px; 
}

.Contacts td {
  vertical-align: top;
  border: 0pt red solid;
}
.Contacts td p {
  line-height: 1em;
  margin-top: 0px;
  margin-bottom: 10px;
  border: 0pt red solid;
}

/* Adaptive APP Bar */

.TitleFrame {
  padding-left: 130px; margin-top: 10px;
}
.TabIcon {
  position: absolute; top: 20px; left: 20px;
  width: 80px; height: 80px; vertical-align: center; border-radius: 50%;
}

.TabIconSpacer {
  width: 0px; height: 1px; flex-shrink: 0;
}

.PageTitle { font-family: Lato,'Open Sans',sans-serif; font-Weight: 300; font-size: 48px; text-align: left; 
  padding-bottom: 0px; margin-bottom: 0px; margin-right: 10px;
  white-space: nowrap;
  display:  block;
} 
.PageSubtitle { font-family:  Lato,'Open Sans',sans-serif; font-Weight: 300; font-size: 34px; text-align:left; padding-top: 0px; margin-top: 0px;
  white-space: nowrap;
  display:  block;

}


@media (min-width: 600px)  {
  .PageSubtitle {
    display: inline;
    
  }

  .PageTitle {
    display: inline;
  }
  
  .TitleFrame {
    padding-left: 160px; margin-top: 10px;
  }
  .TabIcon {
  position: absolute; top: 20px; left: 20px;
  width: 110px; height: 110px; vertical-align: center; border-radius: 50%;
  }
  .TabIconSpacer {
    width: 140px; height: 1px; flex-shrink: 0;
  }
}



/* override to fix tabs jank on resizing */

@media (min-width: 600px)  {
  .AppTabBar .MuiTab-root {
    min-width: 94px;
  }
}
@media (min-width: 700px)  {
  .AppTabBar .MuiTab-root {
    min-width: 105px;
  }
}
@media (min-width: 800px) {
   .AppTabBar .MuiTab-root {
      min-width: 120px;
   }
}
